<template>
	<div class="autocomplete-field">
		<text-field class="autocomplete-field__field form__field" :class="{'form__field--search': search, 'autocomplete-field__field--open': options.length}" :compact="compact" :label="label" v-bind="attrs" @input="input">
			<span slot="pre" v-if="search"><ion-icon name="search"></ion-icon></span>
			<span slot="post" class="autocomplete-field__loading" v-if="loading">
				<loading-spinner :diameter="20" :stroke="2" />
			</span>
		</text-field>
		<ul :id="id" class="mdc-list" role="listbox" v-show="options.length && attrs.value && !selected" :class="{ 'mdc-list--two-line': twoLine }">
			<li
				class="mdc-list-item"
				role="option"
				:key="item.value"
				:tabindex="key"
				v-for="(item, key) in options"
				>
				<span class="mdc-list-item__ripple"></span>
				<span class="mdc-list-item__text">
					<span :class="{'mdc-list-item__primary-text': twoLine }" v-html="item.label"></span>
					<span class="mdc-list-item__secondary-text" v-html="item.alt" v-if="item.alt && twoLine"></span>
				</span>
			</li>
		</ul>
	</div>
</template>

<script>
import { MDCList } from '@material/list'
import { v4 } from 'uuid'
import TextField from '@/components/TextField'
import { debounce } from 'lodash'
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
	name: 'AutocompleteField',
	components: {
		LoadingSpinner,
		TextField
	},
	data: () => ({
		list: null,
		selected: null,
	}),
	props: {
		id: {
			type: String,
			default: v4(),
		},
		label: String,
		options: {
			type: Array,
			default: () => [],
		},
		compact: Boolean,
		loading: Boolean,
		search: Boolean,
		twoLine: Boolean,
	},
	computed: {
		attrs() {
			return this.$attrs
		},
	},
	mounted() {
		this.list = new MDCList(this.$el.querySelector('.mdc-list'))
		this.list.singleSelection = true
		this.list.listen('MDCList:action', (idx) => {
			this.$emit('select', this.options[this.list.selectedIndex])
			this.selected = true
		})
	},
	methods: {
		input: debounce(function (value) {
			this.selected = null
			this.$emit('input', value)
		}, 250),
	},
}
</script>

<style scoped lang="scss">
@use '@material/list';
@include list.core-styles;

.mdc-list {
	background-color: modules.color_('background');
	border: 1px solid modules.color_('border');
	border-radius: modules.$border-radius;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	margin-top: -1px;
	position: absolute;
	width: 100%;
	z-index: 9;
}

.autocomplete-field {
	position: relative;
	width: 100%;

	&__field {
		width: 100%;
	}

	&__loading {
		animation: spin 1000ms linear infinite;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 22px;
		width: 22px;
		transform-origin: 50% 50%;
		right: 0.5em;
	}

	.mdc-list {
		margin-top: -1.5rem;
		max-height: 400px;
		overflow-y: scroll;

		&-item {
			&--selected {
				.mdc-list-item__secondary-text {
					color: rgba(255,255,255,0.8) !important;
				}
			}
		}
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(359deg);
	}
}
</style>
