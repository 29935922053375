<template>
	<autocomplete-field :label="search ? 'Search by Organization' : 'Organization'"  @input="orgSearch" v-model="keyword" :options="orgOptions" @select="orgSelect" :compact="compact" autocomplete="nope" :loading="orgLoading" :search="search" :two-line="true" />
</template>

<script>
import AutocompleteField from '@/components/AutocompleteField'

export default {
	name: 'AutocompleteOrganization',
	components: {
		AutocompleteField,
	},
	props: {
		compact: Boolean,
		search: Boolean,
	},
	data: () => ({
		keyword: null,
		orgOptions: [],
		orgLoading: false,
	}),
	computed: {
		organizations() {
			const orgs = this.$store.getters['organization/organizations']
			return orgs.map(o => { return { value: o.orgId, label: o.name, alt: [o.address?.city, o.address?.state].filter(n => n).join(`, `) }} )
		},
	},
	async mounted() {
		// await this.$store.dispatch('organization/list')
	},
	methods: {
		async orgSearch(value) {
			if (!value || value.length < 3) return ''
			this.orgLoading = true
			const results = await this.$store.dispatch('organization/list', { key: 'keywords.name', op: 'array-contains', value })
			this.orgOptions = results.filter(r => r.status != 'removed').map(r => { return { value: r.orgId, label: r.name, alt: [r.address?.city, r.address?.state].filter(n => n).join(`, `), raw: r }})
			this.orgLoading = false
		},
		orgSelect(selected) {
			this.keyword = selected.label
			this.$emit('selected', selected)
		},
	},
	watch: {
		$attrs: {
			immediate: true,
			handler: function (newVal, oldVal) {
				if (newVal.label ){
					this.keyword = newVal.label
				}
			},
		},
		// organizations(newVal) {
		// 	if (this.$attrs.value) {
		// 		let org = newVal.find(o => o.value == this.$attrs.value)
		// 		if (org) {
		// 			this.keyword = org.label
		// 		}
		// 	}
		// },
	},
}
</script>

<style scoped lang="scss">

</style>
